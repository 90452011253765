import javascript from '../assets/svg/skills/javascript.svg'
import react from '../assets/svg/skills/react.svg'
import typescript from '../assets/svg/skills/typescript.svg'
import vue from '../assets/svg/skills/vue.svg'
import mongoDB from '../assets/svg/skills/mongoDB.svg'
import mysql from '../assets/svg/skills/mysql.svg'
import postgresql from '../assets/svg/skills/postgresql.svg'
import python from '../assets/svg/skills/python.svg'
import ruby from '../assets/svg/skills/ruby.svg'
import git from '../assets/svg/skills/git.svg'

import rails from '../assets/svg/skills/rails.svg'
import linux from '../assets/svg/skills/linux.svg'
import aws from '../assets/svg/skills/aws.svg'
import nodejs from '../assets/svg/skills/nodejs.svg'


export const skillsImage = (skill) => {
    const skillID = skill.toLowerCase();
    switch (skillID) {
        case 'javascript':
            return javascript;
        case 'react js':
            return react;
        case 'typescript':
            return typescript;
        case 'vue js':
            return vue;
        case 'mongodb':
            return mongoDB;
        case 'mysql':
            return mysql;
        case 'postgresql':
            return postgresql;
        case 'python':
            return python;
        case 'ruby':
            return ruby;
        case 'git':
            return git;
        case 'ruby on rails':
            return rails
        case 'linux':
            return linux
        case 'node js':
            return nodejs
        case 'aws':
            return aws
        default:
            break;
    }
}
