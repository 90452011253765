import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'Employee Management',
        projectDesc: 'This project aims to build an employee management system that consolidates all information of a company',
        tags: ['React', 'CSS', 'Material Ui'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: one
    },
    {
        id: 2,
        projectName: 'Railway Tracking',
        projectDesc: 'This project is a railway tracking system that allows users to obtain relevant information about train timing.',
        tags: ['Django', 'Tailwind CSS'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: two
    },
    {
        id: 3,
        projectName: 'Weather App',
        projectDesc: 'Weather forecast systems and applications predict weather conditions based on multiple parameters.',
        tags: ['Django', 'CSS', 'Material Ui'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: three
    }
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/